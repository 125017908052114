import { useState } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import { useNavigate } from "react-router-dom";
import Twofactor from './Twofactor';
import Login from './Login';

function Signin() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const logged = (value) => {
        setIsLoggedIn(value);
    }

    return (
        <div>
            {!isLoggedIn ? <Login isLoggedIn={logged} /> : <Twofactor isLoggedIn={logged} />}

        </div>
    )
}

export default Signin
