import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../assets/edit-icon-1.png";
import deleteIcon from "../../../assets/trash.png";
import swal from "sweetalert";
import SweetAlert from "./SweetAlert";
import { useAuth } from "../../../context/AuthContext";

function Table() {
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);
  const { currentUser } = useAuth();
  const fetchUsers = async () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/getAdmins`, {
      method: "GET",
      headers: {
        Authorization: currentUser.accessToken,
      },
    })
      .then((response) => response.json())
      .then((admins) => {
        console.log("RES", admins.data);
        setData(admins.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const deleteUser = async (id) => {
    let temp = data.filter((item) => item.id != id);
    setData(temp);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/deleteAdmin/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: currentUser.accessToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
        }
      })
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const navigate = useNavigate();
  return (
    <div class="flex flex-col">
      <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full">
              <thead class="bg-text-bg border-b">
                <tr>
                  <th
                    scope="col"
                    class="text-bold font-bold text-gray-900 px-6 py-4 text-center"
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    colSpan={1}
                    class="text-sm font-bold text-gray-900 px-6 py-4 text-center"
                  >
                    First Name
                  </th>
                  <th
                    scope="col"
                    colSpan={1}
                    class="text-sm font-bold text-gray-900 px-6 py-4 text-center"
                  >
                    Last Name
                  </th>
                  <th
                    scope="col"
                    colSpan={1}
                    class="text-sm font-bold text-gray-900 px-6 py-4 text-center"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    colSpan={1}
                    class="text-sm font-bold text-gray-900 px-6 py-4 text-center"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    colSpan={1}
                    class="text-sm font-bold text-gray-900 px-6 py-4 text-center"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {data.map((value, index) => {
                  return (
                    <tr
                      class={`bg-${
                        index % 2 === 0 ? "white" : "text-bg"
                      } border-b`}
                    >
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {index + 1}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {value.firstname}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {value.lastname}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {value.email}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {value.status}
                      </td>
                      <td>
                        <div class="flex items-center justify-center">
                          <button>
                            <img
                              onClick={() =>
                                navigate(`/dashboard/user/edit/${value.id}`)
                              }
                              class="h-6 w-6 m-2  hover:bg-text-bg"
                              src={editIcon}
                              alt="edit"
                            />
                          </button>
                          <button>
                            <img
                              onClick={() => {
                                swal(
                                  "Are yo sure you want to delete this user?",
                                  {
                                    buttons: {
                                      cancel: "Cancel",
                                      delete: true,
                                    },
                                  }
                                ).then((type) => {
                                  switch (type) {
                                    case "delete":
                                      deleteUser(value.id);
                                      //   onDelete();
                                      break;

                                    default:
                                    //   onClose();
                                  }
                                });
                              }}
                              class="h-5 w-5 m-2 hover:bg-secondary "
                              src={deleteIcon}
                              alt="delete"
                            ></img>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
