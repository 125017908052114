import { useNavigate } from 'react-router-dom';
import Layout from '../../Layout';
import Table from './Table';

function Users() {
    const navigate = useNavigate();

    return (
        <>
            <Layout />
            <div>
                <div class="flex items-end justify-end ">
                    <button onClick={() => navigate('/user/create')} type="submit" class={`m-5 w-40 text-white bg-primary hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>Create User</button>
                </div>

                <Table />
            </div>

        </>



    )
}


export default Users;
