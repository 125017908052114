import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";

const AuthContext = React.createContext();
let userID = "";
export function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const fetchUserDetail = async (user) => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/getAdminDetail/${user.uid}`,
      {
        method: "GET",
        headers: {
          Authorization: user?.accessToken,
        },
      }
    )
      .then((response) => response.json())
      .then((admins) => {
        console.log("RES", admins.data.data);
        setCurrentUser({ ...user, ...admins.data.data });
        // setData(admins.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  async function login(email, password) {
    try {
      let loginSession = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return loginSession;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        if (user) {
          fetchUserDetail(user);
        } else {
          setCurrentUser(user);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
    return () => unsubscribe();
  }, []);

  const signOut = async () => {
    setCurrentUser("");
    auth.signOut();
  };

  const value = {
    currentUser,
    login,
    signOut,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
