import { collection, getDocs } from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import SelectInput from "../../components/Select";
import { db } from "../../firebase";

export default function ListCategories() {
  const [cats, setCats] = useState([]);

  const fetchCats = async () => {
    try {
      const categoriesCol = collection(db, "/categories");
      const querySnapshot = await getDocs(categoriesCol);

      const data = [];

      querySnapshot.docs.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setCats(data);
    } catch (e) {
      console.log("e", e);
    }
  };

  useEffect(() => {
    fetchCats();
  }, []);

  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          onClick={() => navigate("/dashboard/categories/create")}
          class="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Add New Category
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Icon
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Cover Photos
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Sub Categories
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {cats.map((cat, id) => {
                  return (
                    <tr key={id}>
                      <td className="px-6 py-4 text-sm text-left font-medium text-gray-800 whitespace-nowrap">
                        {id + 1}
                      </td>
                      <td className="px-6 py-4 text-sm text-left text-gray-800 whitespace-nowrap">
                        {cat.title}
                      </td>
                      <td className="px-6 py-4  text-sm font-medium text-left whitespace-nowrap">
                        <a
                          className="text-green-500 hover:text-green-700"
                          href="#"
                        >
                          <img
                            src={cat.iconUrl}
                            alt=""
                            class="h-6 w-6 flex-shrink-0 rounded-full"
                          />
                        </a>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-left whitespace-nowrap">
                        <a className="text-red-500 hover:text-red-700" href="#">
                          <img
                            src={cat.coverImageUrl}
                            alt=""
                            class="h-6 w-6 flex-shrink-0 rounded"
                          />
                        </a>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-left whitespace-nowrap">
                        <a className="text-red-500 hover:text-red-700" href="#">
                          {cat.status}
                        </a>
                      </td>

                      <td className="px-6 py-4 text-sm text-left text-gray-800 whitespace-nowrap">
                        <NavLink
                          className={""}
                          to={`/dashboard/categories/${cat.id}/sub_categories/create`}
                        >
                          Add
                        </NavLink>
                        <span className="px-3">|</span>
                        <NavLink
                          to={`/dashboard/categories/${cat.id}/sub_categories/view`}
                        >
                          View
                        </NavLink>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        <NavLink to={`/dashboard/categories/${cat.id}/edit`}>
                          Edit
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
