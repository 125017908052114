import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { where, getDocs, collection } from "firebase/firestore/lite";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { db } from "../../firebase";
import useDebounce from "../../hooks/useDebounce";

function AutoCompleteSelect({ onChange, value }) {
  const [selected, setSelected] = useState({});
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [initialResults, setInitialResults] = useState([]);

  const debouncedQuery = useDebounce(query, 500);

  const fetchResults = async () => {
    const subCategoriesCol = collection(db, "categories");

    const querySnapshot = await getDocs(subCategoriesCol);

    const data = [];

    querySnapshot.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });

    setResults(data);
    setInitialResults(data);
  };

  useEffect(() => {
    fetchResults();
  }, []);

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (initialResults.length > 0 && value) {
      setSelected(value);
    }
  }, [initialResults, value]);

  useEffect(() => {
    if (debouncedQuery) {
      const filteredResults = [...results];
      setResults(
        filteredResults.filter((res) =>
          res.title.toLowerCase().includes(debouncedQuery)
        )
      );
    } else {
      setResults(initialResults);
    }
  }, [debouncedQuery]);

  return (
    <div className="w-full">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default rounded-lg bg-white border-gray-300 text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(selected) => selected.title}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full z-50 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {results.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                results.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {person.title}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

export default AutoCompleteSelect;
