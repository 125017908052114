import React, { useEffect, useState } from "react";
import SelectInput from "../../components/Select";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  setDoc,
} from "firebase/firestore/lite";
import {
  uploadBytes,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { db, storage } from "../../firebase";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function AddInterest() {
  const navigate = useNavigate();

  const location = useLocation();

  const isEditView = location.pathname.includes("edit");

  const [subCategory, setSubCategory] = useState({
    title: "",
    status: "draft",
    iconFile: null,
    coverImage: null,
    iconUrl: "",
    coverImageUrl: "",
    parent: null,
  });

  const [category, setCategory] = useState({
    title: "",
    status: "draft",
    iconFile: null,
    coverImage: null,
    iconUrl: "",
    coverImageUrl: "",
  });

  const [interest, setInterest] = useState({
    title: "",
    status: "draft",
    iconFile: null,
    coverImage: null,
    iconUrl: "",
    coverImageUrl: "",
  });

  const [iconUploadPercent, setIconUploadPercent] = useState(0);
  const [coverUploadPercent, setCoverUploadPercent] = useState(0);
  const [addLoading, setAddLoading] = useState(false);

  const onChange = (e, key) => {
    setInterest({
      ...interest,
      [key]: e.target.value,
    });
  };

  const onIconClick = (e) => {
    try {
      debugger;
      const file = interest.iconFile;

      const storageRef = ref(storage, `interests/icons/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setIconUploadPercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setInterest({
              ...interest,
              iconUrl: downloadURL,
            });
          });
        }
      );
    } catch (e) {
      console.log("icon upload error", e);
    }
  };

  const onCoverlick = (e) => {
    const file = interest.coverImage;

    const storageRef = ref(storage, `interests/covers/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setCoverUploadPercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInterest({
            ...interest,
            coverImageUrl: downloadURL,
          });
        });
      }
    );
  };

  useEffect(() => {
    if (interest.coverImage) {
      onCoverlick();
    }
  }, [interest.coverImage]);

  useEffect(() => {
    if (interest.iconFile) {
      onIconClick();
    }
  }, [interest.iconFile]);

  const onSubmit = async (e) => {
    setAddLoading(true);
    e.preventDefault();
    try {
      const categoriesCol = collection(db, "interests");

      if (isEditView) {
        const docRef = doc(db, "interests" + "/" + interestId);
        await setDoc(docRef, {
          title: interest.title,
          iconUrl: interest.iconUrl,
          coverImageUrl: interest.coverImageUrl,
          status: interest.status,
          categoryId: categoryId,
          subCategoryId: subCategoryId,
        });
      } else {
        await addDoc(categoriesCol, {
          title: interest.title,
          iconUrl: interest.iconUrl,
          coverImageUrl: interest.coverImageUrl,
          status: interest.status,
          categoryId: categoryId,
          subCategoryId: subCategoryId,
        });
      }
      setAddLoading(false);
      navigate(-1);
    } catch (e) {
      console.log("e", e);
      setAddLoading(false);
    }
    console.log();
  };

  const { categoryId, subCategoryId, interestId } = useParams();

  console.log("interest", interestId);

  useEffect(() => {
    async function fetchSubCat() {
      const docRef = doc(db, "sub_categories" + "/" + subCategoryId);
      const catDoc = await getDoc(docRef);

      const data = catDoc.data();

      setSubCategory({
        ...subCategory,
        title: data.title,
        iconUrl: data.iconUrl,
        coverImageUrl: data.coverImageUrl,
        status: data.status,
      });
    }

    if (subCategoryId) {
      fetchSubCat();
    }
  }, [subCategoryId]);

  useEffect(() => {
    async function fetchInterest() {
      const docRef = doc(db, "interests" + "/" + interestId);
      const catDoc = await getDoc(docRef);

      const data = catDoc.data();

      console.log("interest::::", data);

      setInterest({
        ...interest,
        title: data.title,
        iconUrl: data.iconUrl,
        coverImageUrl: data.coverImageUrl,
        status: data.status,
      });
    }

    if (interestId) {
      fetchInterest();
    }
  }, [interestId]);

  useEffect(() => {
    async function fetchCat() {
      const docRef = doc(db, "categories" + "/" + categoryId);
      const catDoc = await getDoc(docRef);

      const data = catDoc.data();
      console.log("185", data);

      setCategory({
        ...category,
        title: data.title,
        iconUrl: data.iconUrl,
        coverImageUrl: data.coverImageUrl,
        status: data.status,
      });
    }
    if (categoryId) {
      fetchCat();
    }
  }, [categoryId]);

  const isDisabled =
    !subCategory.title ||
    (iconUploadPercent > 0 && iconUploadPercent < 100) ||
    (coverUploadPercent > 0 && coverUploadPercent < 100);

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6 m-3">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {isEditView ? "Edit Interest" : "Add  Interest"} <br />
            </h3>

            <p className="mt-1 text-sm text-gray-600">* indicates required</p>
          </div>
          <hr className="my-5 bg-primary" />
          {category.title && (
            <h4 className=" p-2 mt-3 rounded-md text-white ">
              <span className="bg-primary p-2 rounded-lg">
                {category.title}
              </span>
            </h4>
          )}
          {subCategory.title && (
            <h4 className=" p-2 mt-3 rounded-md text-white ">
              <span className="bg-primary p-2 rounded-lg">
                {subCategory.title}
              </span>
            </h4>
          )}
        </div>

        <div className="mt-5 md:col-span-2 md:mt-0 text-left">
          <form onSubmit={onSubmit}>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title *
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        value={interest.title}
                        onChange={(e) => onChange(e, "title")}
                        name="interest-title"
                        id="interest-title"
                        className="block w-full p-3 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1 text-gray-700">
                    Icon
                  </label>
                  <div className="mt-1 flex items-center">
                    <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                      {interest.iconUrl ? (
                        <img
                          src={interest.iconUrl}
                          className="h-full w-full mr-5"
                        />
                      ) : (
                        <svg
                          className="h-full w-full text-gray-300 mr-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      )}
                    </span>
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={(e) =>
                          setInterest({
                            ...interest,
                            iconFile: e.target.files[0],
                          })
                        }
                      />
                      <span className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        Upload
                      </span>
                    </label>
                  </div>
                </div>

                <SelectInput
                  onChange={(text) =>
                    setInterest((interest) => ({
                      ...interest,
                      status: text,
                    }))
                  }
                  value={interest.status}
                />

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Cover photo
                    {interest.coverImageUrl && (
                      <label
                        htmlFor="file-upload3"
                        className="relative ml-3 cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Change File</span>
                        <input
                          id="file-upload3"
                          name="file-upload3"
                          type="file"
                          className="sr-only"
                          onChange={(e) => {
                            console.log("here", e.target.files[0]);
                            setInterest({
                              ...interest,
                              coverImage: e.target.files[0],
                            });
                          }}
                        />
                      </label>
                    )}
                  </label>
                  <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    {interest.coverImageUrl ? (
                      <img
                        src={interest.coverImageUrl}
                        className="h-96 w-full object-cover"
                      />
                    ) : (
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload2"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload2"
                              name="file-upload2"
                              type="file"
                              className="sr-only"
                              onChange={(e) =>
                                setInterest({
                                  ...interest,
                                  coverImage: e.target.files[0],
                                })
                              }
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  onSubmit={onSubmit}
                  disabled={isDisabled || addLoading}
                  className="inline-flex disabled:bg-secondary disabled:opacity-60 disabled:cursor-not-allowed justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
