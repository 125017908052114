import { useState, useEffect } from "react"
export default function Dropdown({ title, options, onSelect }) {
    const [visible, setVisible] = useState(false);

    const handleOnClick = (option) => {
        onSelect(option);
        setVisible(false);
    }
    useEffect(() => {
        if (!visible) {
            setVisible(!visible)
        }
    }, [visible])
    return (
        <div class="w-full">
            <div class="group w-full inline-block relative">
                <button
                    class="flex justify-between rounded-lg w-full bg-text-bg text-black font-medium text-sm py-2 px-4 rounded inline-flex items-center"
                >
                    <span class="mr-1">{title}</span>
                    <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                    </svg>
                </button>
                <ul class={`w-full absolute hidden text-gray-700 pt-1  ${visible ? 'group-hover:block' : 'group-hover:none'}`}>
                    {options.map((option) => (

                        <li onClick={() => handleOnClick(option)}>
                            <a
                                class="w-full rounded-t bg-text-bg hover:bg-text-bg py-2 px-4 block whitespace-no-wrap"
                                href="#"
                            >{option}</a
                            >
                        </li>
                    ))}

                </ul>
            </div>
        </div >
    )
}
