import React from "react";
import PrivateRoutes from "../components/PrivateRoutes";
import Signin from "../components/Login/Signin";
import Home from "../components/Dashboard/index.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import Twofactor from "../components/Login/Twofactor";
import Users from "../components/Dashboard/Users/Users";
import CreateUser from "../components/Dashboard/Users/CreateUser";
import AddCategory from "../pages/category/AddCategory";
import ListCategories from "../pages/category/ListCategories";
import Dashboard from "../components/Dashboard/index.jsx";
import AddSubCategory from "../pages/subcategory/AddSubCategory";
import ListSubCategories from "../pages/subcategory/Listsubcategories";
import AddInterest from "../pages/interests/AddInterest";
import ListInterests from "../pages/interests/ListInterests";

export default function AppRoutes() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <div className="App">
            <Routes>
              <Route
                exact
                path="/dashboard"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      {" "}
                      <ListCategories />{" "}
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>
              <Route
                exact
                path="/dashboard/categories/create"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <AddCategory />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>
              <Route
                exact
                path="/dashboard/categories/:categoryId/edit"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <AddCategory />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>
              <Route
                exact
                path="/dashboard/categories/:categoryId/sub_categories/create"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <AddSubCategory />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>
              <Route
                exact
                path="/dashboard/categories/:categoryId/sub_categories/view"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <ListSubCategories />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>
              <Route
                exact
                path="/dashboard/categories/:categoryId/sub_categories/:subCategoryId/edit"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <AddSubCategory />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>

              {/* interests */}
              <Route
                exact
                path="/dashboard/categories/:categoryId/sub_categories/:subCategoryId/interests/create"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <AddInterest />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>
              <Route
                exact
                path="/dashboard/categories/:categoryId/sub_categories/:subCategoryId/interests/view"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <ListInterests />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>
              <Route
                exact
                path="/dashboard/categories/:categoryId/sub_categories/:subCategoryId/interests/:interestId/edit"
                element={
                  <PrivateRoutes>
                    <Dashboard>
                      <AddInterest />
                    </Dashboard>
                  </PrivateRoutes>
                }
              ></Route>

              <Route
                exact
                path="/user/create"
                element={
                  <PrivateRoutes>
                    <CreateUser />
                  </PrivateRoutes>
                }
              />
              <Route
                exact
                path="/dashboard/user/edit/:id"
                element={
                  <PrivateRoutes>
                    <CreateUser />
                  </PrivateRoutes>
                }
              />
              <Route
                exact
                path="/dashboard/user"
                element={
                  <PrivateRoutes>
                    <Users />
                  </PrivateRoutes>
                }
              />
              <Route exact path="/" element={<Signin />} />
              <Route path="/OAuth" element={<Twofactor />} />
            </Routes>
          </div>
        </AuthProvider>
      </Router>
    </div>
  );
}
