import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "../../dropdown";
import Layout from "../../Layout";
import Loading from "../../Loading";
import { useAuth } from "../../../context/AuthContext";

function CreateUser() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [error, setError] = useState("");
  const params = useParams();
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    status: "Active",
  });
  const handleSubmit = () => {
    setIsLoading(true);
    params.id ? editUser() : createUser();
  };
  const validate = (e) => {
    e.preventDefault();
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const passFormat = /[0-9a-zA-Z]{6,}/;
    const { email, firstname, lastname, password } = data;
    let errors = {};
    console.log("logs------00", email == "" || email.match(mailFormat));
    if (email === "" || !email.match(mailFormat)) {
      errors = { ...errors, email: "must be a valid email" };
    }
    if ((!params.id && password.length < 6 || password === "")) {
      errors = {
        ...errors,
        password: "Password must be of minimum 6 charcters",
      };
    }

    if (firstname === "") {
      errors = { ...errors, firstname: `firstname is required` };
    }

    if (lastname == "") {
      errors = { ...errors, lastname: `lastname is required` };
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    } else {
      handleSubmit();
    }
  };
  console.log("err", error);
  useEffect(() => {
    if (params.id) {
      fetch(`${process.env.REACT_APP_API_BASE_UR}/admin/getAdminDetail/${params.id}`, {
        method: "GET",
        headers: {
          Authorization: currentUser.accessToken,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          const { data } = res.data;
          setData({
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            status: data.status,
          });
        })
        .catch((err) => console.log("err", err));
      // setData({
      //     firstname: "John",
      //     lastname: "Johnson",
      //     email: "john12@gmail.com",
      //     password: "123456",
      //     status: "Active",
      // })
    }
    // alert('id', id)
  }, [params.id]);

  const editUser = () => {
    fetch(`process.env.REACT_APP_API_BASE_URL/admin/editAdmin/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: currentUser.accessToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        if (res.status == 200) {
          navigate(-1);
        } else setApiError(res?.error.message);
      })
      .catch((err) => console.log("err", err));
  };

  const createUser = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/createAdmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: currentUser.accessToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        if (res.status == 200) {
          console.log("response");
          navigate(-1);
        } else {
          console.log("res", res.error);
          setApiError(res.error.message);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const disabled =
    data.email == "" || data.firstname == "" || data.lastname == "";
  return (
    <>
      <Layout />

      <section class="bg-white dark:bg-white">
        <div class="flex items-start justify-start">
          <button
            onClick={() => navigate("/dashboard/user")}
            type="submit"
            class={`m-5 w-40 text-white bg-primary hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
          >
            Go Back
          </button>
        </div>
        <div class="flex flex-col items-center  mx-auto md:h-screen lg:py-0 ">
          <a class="flex items-center mb-6 text-2xl font-semibold text-black">
            {params.id ? "Edit User" : "Create User"}
          </a>

          <div class="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 ">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8 border-b-primary">
              <form class="space-y-4 md:space-y-6">
                <div>
                  <label
                    for="firstname"
                    class="block mb-2 text-sm text-left font-medium text-black"
                  >
                    First name
                  </label>
                  <input
                    value={data.firstname}
                    onChange={handleTextChange}
                    type="text"
                    name="firstname"
                    id="firstname"
                    class="bg-text-bg  text-black sm:text-sm rounded-lg bg-secondary focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="John"
                    required
                  />
                  {error?.firstname && (
                    <span className="flex self-start text-sm text-red mt-2">
                      {error.firstname + "*"}
                    </span>
                  )}
                </div>

                <div>
                  <label
                    for="lastname"
                    class="block mb-2 text-sm text-left font-medium text-black"
                  >
                    Last name
                  </label>
                  <input
                    value={data.lastname}
                    onChange={handleTextChange}
                    type="text"
                    name="lastname"
                    id="lastname"
                    class="bg-text-bg  text-black sm:text-sm rounded-lg bg-secondary focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Doe"
                    required
                  />
                  {error?.lastname && (
                    <span className="flex self-start text-sm text-red mt-2">
                      {error.lastname + "*"}
                    </span>
                  )}
                </div>
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm text-left font-medium text-black"
                  >
                    Email
                  </label>
                  <input
                    value={data.email}
                    disabled={params.id}
                    onChange={handleTextChange}
                    type="email"
                    name="email"
                    id="email"
                    class={`bg-text-bg  text-black sm:text-sm rounded-lg bg-secondary focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 `}
                    placeholder="johndoe@email.com"
                    required
                  />
                  {error?.email && (
                    <span className="flex self-start text-sm text-red mt-2">
                      {error.email + "*"}
                    </span>
                  )}
                </div>

                {!params.id && (
                  <div>
                    <label
                      for="password"
                      class="block mb-2 text-left text-sm font-medium text-black"
                    >
                      Password
                    </label>
                    <input
                      value={data.password}
                      onChange={handleTextChange}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      class="bg-text-bg text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                      required
                    />
                    {error?.password && (
                      <span className="flex self-start text-sm text-red mt-2">
                        {error.password + "*"}
                      </span>
                    )}
                  </div>
                )}

                <div>
                  <label
                    for="Status"
                    class="block mb-1 text-sm text-left font-medium text-black"
                  >
                    Status
                  </label>
                  <Dropdown
                    onSelect={(value) => setData({ ...data, status: value })}
                    title={data.status}
                    options={["Active", "Inactive"]}
                  />
                </div>

                <button
                  onClick={validate}
                  disabled={isLoading}
                  class={`w-full text-white bg-primary hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                >
                  {isLoading ? <Loading /> : params.id ? "Edit" : "Create User"}
                </button>
                {apiError && (
                  <span className=" text-sm text-red mt-2">
                    {apiError.message}
                  </span>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateUser;
