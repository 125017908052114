import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext.js";

function PrivateRoutes({ children }) {
    const navigate = useNavigate();
    const { currentUser } = useAuth()
    if (!currentUser) {
        return navigate("/");
    }

    return children
}

export default PrivateRoutes;
