import React from 'react'
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import Loading from '../Loading';

function Login({ isLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const loginSession = await login(email, password);
      setLoading(false);
      console.log("login session from login.jsx", loginSession);
      if (loginSession) {
        isLoggedIn(true);
      } else {
        setApiError("Wrong Email/Password");
      }
    } catch (e) {
      setApiError("Wrong Email/Password");
      console.log("err", e);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const passFormat = /[0-9a-zA-Z]{6,}/;

    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        if (e.target.value.match(mailFormat)) {
          const { email, ...otherProps } = error;
          setError(otherProps);
        } else {
          setError({ ...error, email: "Please use correct email format" });
        }
        break;
      case "password":
        setPassword(e.target.value);
        if (e.target.value.length >= 6) {
          const { password, ...otherProps } = error;
          setError(otherProps);
        } else {
          setError({
            ...error,
            password: "Password must be of minimum 6 charcters",
          });
        }
        break;
      default:
    }
  };

  return (
    <section class="bg-white dark:bg-white">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="flex items-center justify-center mb-10">
          <img class=" object-contain h-20 w-20" src="icon2.png" />
        </div>
        <a class="flex items-center mb-6 text-2xl font-semibold text-black">
          Admin Panel Login
        </a>

        <div class="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8 border-b-primary">
            <form class="space-y-4 md:space-y-6" onSubmit={handleLogin}>
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm text-left font-medium text-black"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  id="email"
                  class="bg-text-bg  text-black sm:text-sm rounded-lg bg-secondary focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="name@company.com"
                  required
                />
                {error?.email && (
                  <span className="flex self-start text-sm text-red mt-2">
                    {error.email + "*"}
                  </span>
                )}
              </div>
              <div>
                <label
                  for="password"
                  class="block mb-2 text-left text-sm font-medium text-black"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  onChange={(e) => handleChange(e)}
                  id="password"
                  placeholder="••••••••"
                  class="bg-text-bg text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  required
                />
                {error?.password && (
                  <span className="flex self-start text-sm text-red mt-2">
                    {error.password + "*"}
                  </span>
                )}
              </div>
              {/* <div class="ml-2 ">
                                <div class="flex items-left">
                                    <div class="flex self-center h-5">
                                        <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                                    </div>
                                </div>
                            </div> */}
              <button
                type="submit"
                class={`w-full text-white bg-primary hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                  Object.keys(error).length >= 1
                    ? "cursor-not-allowed opacity-40"
                    : "cursor-auto opacity-100"
                }`}
                disabled={loading || Object.keys(error).length >= 1}
                onClick={handleLogin}
              >
                Log in
              </button>
              {apiError && (
                <span className=" text-sm text-red mt-2">{apiError}</span>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
