import React from "react";
import { useNavigate } from "react-router-dom";

export default function Twofactor({ isLoggedIn }) {
    const navigate = useNavigate();
    return (
        <section class="bg-white dark:bg-white">

            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div class="flex items-center justify-center mb-10">
                    <img class=" object-contain h-20 w-20" src='icon2.png' />
                </div>
                <a class="flex items-center mb-6 text-2xl font-semibold text-black">
                    OTP Verification
                </a>

                <div class="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 ">

                    <div class="h-80 p-6 space-y-4 md:space-y-6 sm:p-8 border-b-primary">

                        <form onSubmit={(e) => e.preventDefault()} class=" space-y-4 md:space-y-6">
                            <div class="mb-4 mt-2">
                                <label for="email" class="block mb-2 text-sm text-left font-medium text-black">Enter the 6 digit code that you recieved by SMS / Email</label>
                                <input name="otp" id="otp" class="bg-text-bg pt-4 text-black sm:text-sm rounded-lg bg-secondary focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="6 digit OTP" />
                            </div>

                            <button onClick={() => navigate("/dashboard")} class={`w-full text-white bg-primary hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>Submit</button>
                            <button onClick={() => navigate("/")} class={`w-full text-black bg-white border-2    hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>Cancel</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    )

}
