import { initializeApp } from "firebase/app";
import {
  getAuth,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";
// require('dotenv').config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_API_KEY_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_API_KEY_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_API_KEY_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_KEY_APP_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app, "gs://racqy-132b6.appspot.com");
export const db = getFirestore(app);

(async () => {
  await setPersistence(auth, browserLocalPersistence);
})();
